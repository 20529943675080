import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`“9/11 Throwdown”`}</strong></p>
    <p>{`2001 m Row`}</p>
    <p>{`11 Box Jumps (36/24)`}</p>
    <p>{`11 Thrusters (125/85)`}</p>
    <p>{`11 Burpee to Chest 2 Bar Pull-ups`}</p>
    <p>{`11 Power Cleans (175/120)`}</p>
    <p>{`11 HSPU`}</p>
    <p>{`11 KB Swings (70/53)`}</p>
    <p>{`11 Toes 2 Bar`}</p>
    <p>{`11 Deadlift (170/120)`}</p>
    <p>{`11 Push Jerk (110/75)`}</p>
    <p>{`2001 m Row`}</p>
    <p><strong parentName="p">{`*`}{`We will be open from 9 — 11am today (unless we have alot of
people) to do this WOD. Remember this is to honor all men and women who
perished  10 years ago today and those who have perished since then in
the fight against terrorism. You can donate money (that goes to the
Disposable Heros Project) or donate Sweat…`}</strong></p>
    <p>{`Ten years ago the make up of human existence was forever altered. The
attacks on\\
September 11 2001 challenged the core values of American society. Over
the last\\
ten years we have fought harder than ever imagined to rebuild and
restore\\
America’s values. In our darkest hour we surged through to the light on
the\\
backs of the military and patriotic people across the nation.\\
On September 11 2011 we mark the ten-year anniversary of the attacks on
America. In order to\\
honor the sacrifice of those who lost their lives on that horrific day
as well\\
as the War on Terror we are asking everyone to come together and
complete this epic WOD with us.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      